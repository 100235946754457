<template>
  <div>
    <FeaturedCarouselPlaceholder v-if="gotFeatured == false"></FeaturedCarouselPlaceholder>
     <Hero v-if="gotFeatured" :contents="contents"></Hero>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";

export default {
  props: {
    featuredScreen: {
      type: Object,
    },
  },
  data() {
    return {
      gotFeatured: false,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "subscriptionList"]),
  },
  watch: {
    $route() {
      console.log("Route changed");
      this.getContentList();
    },
    subscriberid(val) {
      if (val) {
        this.getContentList();
      }
    },
    subscriptionList(val) {
      if (val) {
        this.getContentList();
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;

    eventBus.$on("featured-response", (response) => {

      if (!response.reason) {
        this.contents = [...response.data];
        setTimeout (() => {
          this.gotFeatured = true;
          eventBus.$emit("featured-loaded", true);

        }, 500)

        this.contents.map((item, index) => {
          if (index == 0) {
            return item.isActiveBorder = true;
          } else {
            return item.isActiveBorder = false;
          }
        })
        this.addScreensInPosition();

  
      } else {
        eventBus.$emit("featured-loaded", true);

        this.gotFeatured = null;
      }
    });

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.getContentList();
    });
  },
  mounted() {
    this.getContentList();
    eventBus.$on('featured-carousel', this.getContentList);
  },
  methods: {
    getUserType() {
      if (!this.subscriberid) {
        return "GUEST";
      } else if (this.subscriberid && this.subscriptionList.length > 0) {
        return "SUBSCRIBED";
      } else {
        return "REGISTERED";
      }
    },
    getEndPoint () {
      const sectionType = this.featuredScreen.sectionType;
      const itemType = this.featuredScreen.itemType;
      
      if (sectionType == "ITEMLIST" && itemType == "CONTENT") {
        return "/subscriber/v1/content"
      } else if (sectionType == "ITEMLIST" && itemType == "TRAILERS") {
         return "/subscriber/v1/metadata/trailer"
      } else if (sectionType == "ITEMLIST" && itemType == "CASTNCREW") {
         return "/subscriber/v1/metadata/castncrew"
      } else {
         return "/subscriber/v1/content"
      }
    },
    getContentList() {
      // To show placeholder.
      this.gotFeatured = false;

      let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if(subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      let payload = {
        endpoint: this.featuredScreen.endpoint || this.getEndPoint(),
        params: this.featuredScreen.parameters || this.featuredScreen.sectionData,
      };

      payload.params.displaylanguage = this.localDisplayLang;

      let temporaryVideoTag = document.createElement("video");

      let platformOs = navigator.platform;

      if (temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          payload.params.mpegtspackage = "YES";
        }
      }

      // if(subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
      //   payload.params.genre = "Kids";
      // }

      eventBus.$emit("featured-list", payload);
    },
    addScreensInPosition() {
      //add the subSontents Slides.
      if (this.featuredScreen && this.featuredScreen.subContents && this.featuredScreen.subContents.length > 0) {
        this.featuredScreen.subContents.forEach((element) => {
          if (element.isEnabled && element.userTypes.includes("ALL")) {
            this.contents.splice(element.position, 0, element);
          } else if (element.isEnabled && element.userTypes.includes(this.getUserType())) {
            this.contents.splice(element.position, 0, element);
          }
        });
      }
    },
  },
  components: {
    FeaturedCarouselPlaceholder: () =>
      import(/* webpackChunkName: "featplaceholder" */ "@/components/placeholders/FeaturedCarouselPlaceholder.vue"),
    Hero: () =>
      import(
        /* webpackChunkName: "HERO" */ '@/themeearth/components/dashboard/herocarousel/HeroComponent.vue'
      ),
  },
};
</script>

<style lang="scss" scoped></style>
